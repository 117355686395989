<template>
  <div class="hold-transition">
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Nominaciones</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item">
                    <router-link to="/Admin">Administrativo</router-link>
                  </li>
                  <li class="breadcrumb-item active">Nominaciones</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-3">
                    Buscar en la tabla<br />
                    <input type="text" v-model="filter" />
                  </div>
                  <div class="col-md-8 text-center">
                    <div
                      v-if="loading"
                      class="spinner-border text-dark"
                      role="status"
                    >
                      <span class="sr-only">Guardando...</span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-11"></div>
                  <div class="col-md-1">
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn btn-success"
                        data-toggle="modal"
                        data-target="#modal-form-reporte-nominacion"
                        v-if="$store.getters.can('hidrocarburos.reportes.nominacionExport')"
                      >
                        <i class="far fa-file-excel"></i>
                      </button>
                      <router-link to="/Hidrocarburos/NominacionesCreate">
                        <button
                          type="button"
                          class="btn btn-primary"
                          @click="newItem = true"
                          v-if="
                            $store.getters.can(
                              'hidrocarburos.nominaciones.create'
                            )
                          "
                        >
                          <i class="fas fa-plus"></i>
                        </button>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body p-0">
                <table
                  class="table table-bordered table-striped table-hover table-sm"
                >
                  <thead>
                    <tr>
                      <th>
                        Nombre
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="nombre"
                          @keyup="filtrarTabla()"
                        />
                      </th>
                      <th>
                        Fecha inicial
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="fecha_inicial"
                          @keyup="filtrarTabla()"
                        />
                      </th>
                      <th>
                        Fecha final
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="fecha_final"
                          @keyup="filtrarTabla()"
                        />
                      </th>
                      <th>
                        Estado
                        <select
                          v-model="estado"
                          @change="filtrarTabla()"
                          class="form-control form-control-sm"
                        >
                          <option
                            v-for="r in listas"
                            v-bind:key="r.id"
                            v-bind:value="r.numeracion"
                          >
                            {{ r.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th>Fecha creación</th>
                      <th align="center">Opciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(row, index) in filteredRows"
                      :key="`id-${index}`"
                    >
                      <td v-html="highlightMatches(row.nombre)"></td>
                      <td v-html="highlightMatches(row.fecha_inicial)"></td>
                      <td v-html="highlightMatches(row.fecha_final)"></td>
                      <td class="text-center">
                        <span
                          :class="
                            row.estado == 1
                              ? 'badge badge-warning'
                              : 'badge badge-success'
                          "
                        >
                          {{ row.nEstado }}
                        </span>
                      </td>
                      <td
                        v-html="
                          highlightMatches(row.created_at.substring(0, 10))
                        "
                      ></td>
                      <td>
                        <div class="btn-group float-left">
                          <router-link
                            :to="'/Hidrocarburos/NominacionesEdit/' + row.id"
                          >
                            <button
                              type="button"
                              class="btn btn-sm bg-navy"
                              v-if="
                                $store.getters.can(
                                  'hidrocarburos.nominaciones.show'
                                )
                              "
                            >
                              <i class="fas fa-edit"></i>
                            </button>
                          </router-link>
                          <button
                            type="button"
                            class="btn btn-sm btn-danger"
                            v-if="
                              $store.getters.can(
                                'hidrocarburos.nominaciones.delete'
                              ) && row.estado != 2
                            "
                            @click="delNominacion(row.id)"
                          >
                            <i class="fas fa-trash-alt"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="nominaciones.total">
                  <p>
                    Mostrando del <b>{{ nominaciones.from }}</b> al
                    <b>{{ nominaciones.to }}</b> de un total:
                    <b>{{ nominaciones.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="nominaciones"
                  @pagination-change-page="filtrarTabla"
                  :limit="5"
                  align="right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
      <NominacionExport ref="NominacionExport" />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import serverConfig from "./../../../../services/config";
import pagination from "laravel-vue-pagination";
import NominacionExport from "./NominacionExport";

export default {
  name: "NominacionEdit",
  components: {
    pagination,
    NominacionExport,
  },
  data() {
    return {
      nominacionesTabla: [],
      nombre: null,
      fecha_inicial: null,
      fecha_final: null,
      estado: null,
      filter: "",
      filtros: {},
    };
  },
  beforeMount() {
    (this.filtros = {
      nombre: null,
      fecha_inicial: null,
      fecha_final: null,
      estado: null,
    }),
      this.actGetNominaciones(this.filtros).then(() => {
        this.nominacionesTabla = this.nominaciones.data;
      });
    this.actGetListas(28);
  },
  computed: {
    ...mapState("modOperacion", ["listas"]),
    ...mapState("modHidrocarburos", ["nominaciones", "loading"]),

    filteredRows() {
      return this.nominacionesTabla.filter((row) => {
        const nombre = row.nombre.toLowerCase();
        const fecha_inicial = row.fecha_inicial; //.toString().toLowerCase();
        const fecha_final = row.fecha_final; //.toLowerCase();
        const estado = row.estado == 1 ? "borrador" : "definitivo"; //.toLowerCase();
        const created_at = row.created_at; //direccion) ? row.direccion.toLowerCase() : '';
        const searchTerm = this.filter.toLowerCase();

        return (
          nombre.includes(searchTerm) ||
          fecha_inicial.includes(searchTerm) ||
          fecha_final.includes(searchTerm) ||
          estado.includes(searchTerm) ||
          created_at.includes(searchTerm)
        );
      });
    },
  },
  methods: {
    ...mapActions("modOperacion", ["actGetListas"]),
    ...mapActions("modHidrocarburos", [
      "actGetNominaciones",
      "actDeleteNominacion",
    ]),

    highlightMatches(texto) {
      var text = String(texto);
      const matchExists = text
        .toLowerCase()
        .includes(this.filter.toLowerCase());
      if (!matchExists) return text;

      const re = new RegExp(this.filter, "ig");
      return text.replace(
        re,
        (matchedText) => `<strong>${matchedText}</strong>`
      );
    },

    filtrarTabla(page = 1) {
      var data = [];
      (this.filtros = {
        nombre: this.nombre ? this.nombre : null,
        fecha_inicial: this.fecha_inicial ? this.fecha_inicial : null,
        fecha_final: this.fecha_final ? this.fecha_final : null,
        estado: this.estado ? this.estado : null,
      }),
        (data[0] = page);
      data[1] = this.filtros;
      //alert(JSON.stringify(this.filtros));
      this.nominacionesTabla = [];
      this.actGetNominaciones(data).then(() => {
        this.nominacionesTabla = this.nominaciones.data;
      });
    },

    /*editNominacion(id){
      this.$router.push({
        name: "/Hidrocarburos/NominacionesCreate",
        params: { id: id }
      });
    },*/

    delNominacion(id) {
      this.$swal({
        title: "Esta seguro de eliminar la Nominación: " + id + "?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        // <--
        if (result.value) {
          this.actDeleteNominacion(id).then(() => {
            this.actGetNominaciones(this.filtros).then(() => {
              this.nominacionesTabla = this.nominaciones.data;
            });
            this.$swal({
              icon: "success",
              title: "Se eliminó la Nominación exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 1000,
              timerProgressBar: true,
            });
          });
        }
      });
    },
  },
};
</script>

<style lang="scss">
[v-cloak] {
  display: none;
}
.edit {
  display: none;
}
.editing .edit {
  display: block;
}
.editing .view {
  display: none;
}
</style>
